<template>
    <div class="bg-gray-50">
    <div class="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
      <div class="max-w-3xl mx-auto divide-y-2 divide-gray-200">
        <h2 class="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
          Login
        </h2>
      </div>

              <section class="p-6" id="firebaseui-auth-container"></section>

    </div>
    </div>
</template>

<script>
import firebase from "../../firebase"
import * as firebaseui from "firebaseui"
import "firebaseui/dist/firebaseui.css"

export default {
    mounted() {
        let ui = firebaseui.auth.AuthUI.getInstance()
        if (!ui) {
            ui = new firebaseui.auth.AuthUI(firebase.auth())
        }

        let uiConfig = {
            signInSuccessUrl: "/profile",
            signInOptions: [
                firebase.auth.FacebookAuthProvider.PROVIDER_ID,
                firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                firebase.auth.EmailAuthProvider.PROVIDER_ID 
            ]
        }
        ui.start("#firebaseui-auth-container", uiConfig)
    }
}
</script>
